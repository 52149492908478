class RightMenu {

    constructor() {
        this.selector = $('[data-toggle="right-menu"]');
        this.addEvent();
    }

    addEvent(){
        this.selector.click((ev)=>{
            let selectorName = $(ev.currentTarget).attr('data-target');
            $(selectorName).toggleClass('fixed-menu-open');
        });
    }

}